import axios from 'axios';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';

import { ACCESS_TOKEN, DEFAULT_ACCESS_TOKEN_LIFE, DEFAULT_REFRESH_TOKEN_LIFE, REFRESH_TOKEN } from '@/app/config/cookies';
import { checkIsMatchedRoute } from '@/app/config/router';

export const baseURL = process.env.NEXT_PUBLIC_API_URL || process.env.NEXT_PUBLIC_APP_URL || 'http://localhost:3000';

export async function refreshAccessToken (refreshToken?: string): Promise<{ newAccessToken: string, newRefreshToken: string } | null> {
    if (!refreshToken) return null;

    try {
        const response = await fetch(baseURL + '/auth/refresh-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken}`
            },
            body: JSON.stringify({
                // eslint-disable-next-line camelcase
                refreshToken
            }),
            cache: 'no-cache'
        });
        const { accessToken: newAccessToken, refreshToken: newRefreshToken } = await response.json() || {};

        if (!newAccessToken || !newRefreshToken) {
            throw new Error('No access token or refresh token in response');
        }

        return {
            newAccessToken,
            newRefreshToken
        };
    } catch (e) {
        return null;
    }
}

const $api = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json'
    }
});

$api.interceptors.request.use(async config => {
    const token = getCookie(ACCESS_TOKEN);

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
});

$api.interceptors.response.use(
    response => response,
    async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const { newAccessToken, newRefreshToken } = await refreshAccessToken(getCookie(REFRESH_TOKEN)) || {};

            if (!newAccessToken || !newRefreshToken) {
                deleteCookie(ACCESS_TOKEN);
                deleteCookie(REFRESH_TOKEN);

                const redirectTo = checkIsMatchedRoute({ pathname: window.location.pathname, user: null });

                if (redirectTo) {
                    window.location.href = redirectTo.path;
                }
                return;
            }

            setCookie(ACCESS_TOKEN, newAccessToken, { maxAge: DEFAULT_ACCESS_TOKEN_LIFE });
            setCookie(REFRESH_TOKEN, newRefreshToken, { maxAge: DEFAULT_REFRESH_TOKEN_LIFE });


            axios.defaults.headers.common['Authorization'] = 'Bearer ' + newAccessToken;
            originalRequest.headers['Authorization'] = 'Bearer' + newAccessToken;

            return $api(originalRequest);
        }
        return Promise.reject(error);
    });

export default $api;

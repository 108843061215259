
import { OptionType } from 'dayjs';
import { useLocale, useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { email, max, maxLength, min, minLength, required, sameAs, url, validDate } from '@/app/lib/validators';
import { Paths } from '@/app/types/shared';


enum ValidationHelpers {
    required = 'required',
    minLength = 'minLength',
    maxLength = 'maxLength',
    email = 'email',
    sameAs = 'sameAs',
    validDate = 'validDate',
    min = 'min',
    max = 'max',
    password = 'password',
    url = 'url'
}

export type ValidationReturnType = Promise<true | Paths>
export type IValidationHelpersIndividual = {
    maxLength: {
        maxLength: number
    }
    minLength: {
        minLength: number
    }
    sameAs: {
        compareFunc: (args: { value: string }) => boolean
    }
    validDate: {
        format?: OptionType
        maxDate?: string
        minDate?: string
    },
    password: {
        minLength: number
    },
    min: {
        min: number
    },
    max: {
        max: number
    }
}
export type IValidationHelpersShared = {
    customErrorKey?: Paths
}

export type IValidatorArgs = {
    [K in keyof typeof ValidationHelpers]: {
        value: string
    } & IValidationHelpersShared & (K extends keyof IValidationHelpersIndividual ? IValidationHelpersIndividual[K] : {})
}

type ValidatorFunction<K> = (args: K) => ValidationReturnType;
type ValidatorWithMessage<T> = (args: T) => Promise<string | undefined>;

export default function useValidators () {
    const locale = useLocale();
    const t = useTranslations();

    return useMemo(() => {
        function withMessage<T extends IValidationHelpersShared> (validator: ValidatorFunction<T>): ValidatorWithMessage<T> {
            return async args => {
                const validationResult = await validator(args);

                if (typeof validationResult === 'string') {
                    return t(args.customErrorKey || validationResult, { ...(args || {}) });
                }
            };
        }

        return {
            maxLength: withMessage(maxLength),
            minLength: withMessage(minLength),
            required: withMessage(required),
            email: withMessage(email),
            sameAs: withMessage(sameAs),
            validDate: withMessage(validDate),
            min: withMessage(min),
            max: withMessage(max),
            // password: withMessage(password),
            url: withMessage(url)
        };
    }, [locale]);
}

import dayjs, { DEFAULT_FORMAT } from '@/app/lib/dayjs';
import { IValidatorArgs, ValidationReturnType } from '@/app/shared-composables/useValidators';

export const required = async ({ value }: IValidatorArgs['required']): ValidationReturnType => {
    const errorKey = 'validationErrors.required';

    try {
        if (Array.isArray(value)) {
            return !!value?.length || errorKey;
        }

        return !!value || errorKey;
    } catch (e) {
        return errorKey;
    }
};

export const minLength = async ({ value, minLength }: IValidatorArgs['minLength']): ValidationReturnType => {
    if (!value) return true;

    const errorKey = 'validationErrors.minLength';
    try {
        return value.length >= minLength || errorKey;
    } catch (e) {
        return errorKey;
    }
};

export const maxLength = async ({ value, maxLength }: IValidatorArgs['maxLength']): ValidationReturnType => {
    if (!value) return true;

    const errorKey = 'validationErrors.maxLength';

    try {
        return value.length <= maxLength || errorKey;
    } catch (e) {
        return errorKey;
    }
};

export const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const email = async ({ value }: IValidatorArgs['email']): ValidationReturnType => {
    if (!value) return true;

    const errorKey = 'validationErrors.email';

    try {
        return emailRegex.test(value) || errorKey;
    } catch (e) {
        return errorKey;
    }
};

export const sameAs = async ({ value, compareFunc }: IValidatorArgs['sameAs']): ValidationReturnType => {
    if (!value) return true;

    const errorKey = 'validationErrors.sameAs';
    try {
        return compareFunc({ value }) || errorKey;
    } catch (e) {
        return errorKey;
    }
};

export const validDate = async ({ value, format, maxDate, minDate }: IValidatorArgs['validDate']): ValidationReturnType => {
    if (!value) return true;
    const errorKey = 'validationErrors.validDate';
    try {
        let shouldShowError;
        const isValidDate = dayjs(value, format || DEFAULT_FORMAT).isValid();
        const isAfterMax = dayjs(value, format || DEFAULT_FORMAT).isAfter(dayjs(maxDate, format || DEFAULT_FORMAT));
        const isBeforeMin = dayjs(value, format || DEFAULT_FORMAT).isBefore(dayjs(minDate, format || DEFAULT_FORMAT));

        shouldShowError = !isValidDate || (maxDate && isAfterMax) || (minDate && isBeforeMin);

        return !shouldShowError || errorKey;
    } catch (e) {
        return errorKey;
    }
};

export const min = async ({ value, min }: IValidatorArgs['min']): ValidationReturnType => {
    if (!value) return true;
    const errorKey = 'validationErrors.min';
    try {
        return (+value && +value >= min) || errorKey;
    } catch (e) {
        return errorKey;
    }
};

export const max = async ({ value, max }: IValidatorArgs['max']): ValidationReturnType => {
    if (!value) return true;
    const errorKey = 'validationErrors.max';
    try {
        return (+value && +value <= max) || errorKey;
    } catch (e) {
        return errorKey;
    }
};

export const urlRegex = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator

export const url = async ({ value }: IValidatorArgs['url']): ValidationReturnType => {
    if (!value?.length) return true;
    const errorKey = 'validationErrors.url';

    try {
        return urlRegex.test(value) || errorKey;
    } catch (e) {
        return errorKey;
    }
};

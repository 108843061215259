import { USER_ROLES, UserRoles } from "@queries/user/me/config";
import { GetReturnType } from "@queries/user/me/types";

export type Route = {
    id: string
    path: string
    exact?: boolean
    role?: UserRoles
    authorized?: boolean
}

const adminRoutes = [
    {
        id: 'rootPage',
        path: '/',
        exact: true,
        role: USER_ROLES.admin, // temporary fix
        authorized: true
    },
    {
        id: 'adminTables',
        path: '/admin/tables',
        authorized: true,
        role: USER_ROLES.admin
    },
    {
        id: 'adminUsers',
        path: '/admin/users',
        authorized: true,
        role: USER_ROLES.admin
    },
    {
        id: 'adminRoles',
        path: '/admin/roles',
        authorized: true,
        role: USER_ROLES.admin
    },
    {
        id: 'adminProfile',
        path: '/admin/profile',
        authorized: true,
        role: USER_ROLES.admin
    }
] as const satisfies Array<Route>;

const salesManagerRoutes = [
    {
        id: 'tables',
        path: '/tables',
        authorized: true,
        role: USER_ROLES.salesManager
    },
    {
        id: 'createTable',
        path: '/tables/create-table',
        authorized: true,
        role: USER_ROLES.salesManager
    }
] as const satisfies Array<Route>;

const unauthorizedRoutes = [
    {
        id: 'login',
        path: '/login'
    },
    {
        id: 'resetPassword',
        path: '/reset-password'
    },
    {
        id: 'resetPasswordConfirm',
        path: '/reset-password-confirm'
    }
] as const satisfies Array<Route>;

export const routes = [
    ...adminRoutes,
    ...salesManagerRoutes,
    ...unauthorizedRoutes
] as const satisfies Array<Route>;

export const redirectUnauthorizedTo = routes.find(route => route.id === 'login') as Route;
export const redirectAuthorizedTo: Record<UserRoles, Route> = {
    ADMIN: getRouteById('adminTables') as Route,
    SALES_MANAGER: getRouteById('tables') as Route
};

export function getRouteById<T extends typeof routes[number]['id']> (id: T): Extract<typeof routes[number], { id: T }> | undefined {
    return routes.find((route): route is Extract<typeof routes[number], { id: T }> => route.id === id);
}

export function checkIsMatchedRoute ({ pathname, user }: { pathname: string, user?: GetReturnType | null }): Route | false {
    const foundRouteObj = routes.find(route => {
        if ('exact' in route && route.exact) {
            return pathname === route.path;
        } else {
            return pathname.startsWith(route.path);
        }
    });

    if (!foundRouteObj) return redirectUnauthorizedTo;

    const isFoundRouteForAuthorized = 'authorized' in foundRouteObj && foundRouteObj.authorized;
    const isFoundRouteForUnauthorized = !('authorized' in foundRouteObj) || !foundRouteObj.authorized;
    const isFoundRouteHasRole = 'role' in foundRouteObj;

    // if authorized route and user is falsy
    if (!user && isFoundRouteForAuthorized) {
        return redirectUnauthorizedTo;
    }

    // if not authorized route but user exists
    if (user && isFoundRouteForUnauthorized) {
        return redirectAuthorizedTo[user.role];
    }

    // if authorized route and user exist but route doesn`t match role
    if (user && isFoundRouteHasRole && user.role !== foundRouteObj.role) {
        return redirectAuthorizedTo[user.role];
    }

    return false;
}
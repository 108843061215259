'use client';

import { PasswordInput, PasswordInputProps, Popover, Progress, Text } from "@mantine/core";
import { IconCheck, IconX } from '@tabler/icons-react';
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { forwardRef, memo, useState } from "react";

import { Paths } from "@/app/types/shared";

import styles from './index.module.scss';

type PasswordFieldWithStrengthProps = PasswordInputProps;

function PasswordRequirement ({ meets, label, args }: { meets: boolean; label: Paths, args?: Record<string, any> }) {
    const t = useTranslations();

    return (
        <Text className={clsx([styles.passwordRequirement, { [styles.meets]: meets }])}>
            {meets ? (
                <IconCheck className={styles.passwordRequirementIcon} />
            ) : (
                <IconX className={styles.passwordRequirementIcon} />
            )}
            <span className={styles.passwordRequirementLabel}>
                {t(label, { ...(args || {}) })}
            </span>
        </Text>
    );
}

export const passwordMinLength = 6;
export const passwordRequirements: Array<{ validator: (value: string) => boolean, label: Paths, args?: Record<string, any> }> = [
    { validator: (value: string) => value?.length >= passwordMinLength, label: 'validationErrors.password.minLength', args: { minLength: passwordMinLength } },
    { validator: (value: string) => /[0-9]/.test(value), label: 'validationErrors.password.includesNumbers' },
    { validator: (value: string) => /[a-z]/.test(value), label: 'validationErrors.password.includesLowercase' },
    { validator: (value: string) => /[$&+,:;=?@#|'<>.^*()_%!-]/.test(value), label: 'validationErrors.password.includesSpecial' }
];

function getStrength (password: string) {
    let multiplier = 0;

    passwordRequirements.forEach(requirement => {
        if (!requirement.validator(password)) {
            multiplier += 1;
        }
    });

    return Math.max(100 - ((100 / passwordRequirements.length) * multiplier), 10);
}


const PasswordFieldWithStrength = forwardRef<HTMLInputElement, PasswordFieldWithStrengthProps>(function PasswordFieldWithStrength (props, ref) {
    const [popoverOpened, setPopoverOpened] = useState(false);
    const checks = passwordRequirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.validator(String(props.value || ''))} args={requirement.args} />
    ));

    const strength = getStrength(String(props.value || ''));
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

    return (
        <Popover opened={popoverOpened} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
            <Popover.Target>
                <div
                    onFocusCapture={() => setPopoverOpened(true)}
                    onBlurCapture={() => setPopoverOpened(false)}
                >
                    <PasswordInput
                        {...props}
                        ref={ref}
                    />
                </div>
            </Popover.Target>
            <Popover.Dropdown>
                <Progress color={color} value={strength} size={5} mb="xs" />
                {checks}
            </Popover.Dropdown>
        </Popover>
    );
});

export default memo(PasswordFieldWithStrength) as typeof PasswordFieldWithStrength;